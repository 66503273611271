.react-phone-div {
  width: 100%;
  padding: 0px !important;
  background-color: transparent;
  border: none !important;
  .phone {
    width: 100% !important;
    .react-tel-input {
      width: 100% !important;
      max-width: 100% !important;
      .form-control {
        width: 100% !important;
        background-color: var(--inputColor) !important;
        border: 1px solid var(--borderColor) !important;
      }
      .flag-dropdown {
        background-color: var(--inputColor) !important;
        border-top: 0px !important;
        border-right: 1px solid var(--borderColor) !important;
        border-bottom: 1px solid var(--borderColor) !important;
        //border-bottom: 0px !important;
        border-left: 0px !important;
        margin-top: 2px;
        margin-left: 2px;
        .arrow {
          border-top: 4px solid #fff !important;
        }
        .country-list {
          background-color: var(--fourthColor) !important;
          text-align: left;
          .dial-code {
            color: #fff !important;
          }
          .highlight {
            .country-name {
              color: var(--fourthColor) !important;
            }
            .dial-code {
              color: var(--fourthColor) !important;
            }
          }
          li:hover {
            .country-name {
              color: var(--fourthColor) !important;
            }
            .dial-code {
              color: var(--fourthColor) !important;
            }
          }
        }
      }
      .flag-dropdown:hover {
        .selected-flag {
          background-color: var(--fourthColor) !important;
        }
      }
      .flag-dropdown:focus {
        .selected-flag {
          background-color: var(--fourthColor) !important;
        }
      }
    }
  }
}
