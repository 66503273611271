:root {
  --main-color-light: #fff;
  --text-color-light: #000;
  --border-color-light: rgba(0, 0, 0, 0.2);
  --secondary-color-light: #ebedef;
  --input-color-light: #fff;
  --dim-color-light: #595757;

  --main-color-dark: #3a4149;
  --text-color-dark: #fff;
  --border-color-dark: rgba(0, 0, 0, 0.5);
  --secondary-color-dark: #343b41;
  --input-color-dark: #515b65;
  --dim-color-dark: rgb(153, 153, 153);

  --active-blue-color: #20a8d8;
  --active-red-color: #c77171;

  --secondColor: #23282c;
}

.light-theme {
  --mainColor: var(--main-color-light);
  --activeColor: var(--active-blue-color);
  --textColor: var(--text-color-light);
  --borderColor: var(--border-color-light);
  --redColor: var(--active-red-color);
  --fourthColor: var(--secondary-color-light);
  --inputColor: var(--input-color-light);
  --logoColor: var(--active-blue-color);
  --navHoverColor: var(--active-blue-color);
  --dimColor: var(--dim-color-light);
}

.dark-theme {
  --mainColor: var(--main-color-dark);
  --activeColor: var(--active-blue-color);
  --textColor: var(--text-color-dark);
  --borderColor: var(--border-color-dark);
  --redColor: var(--active-red-color);
  --fourthColor: var(--secondary-color-dark);
  --inputColor: var(--input-color-dark);
  --logoColor: var(--main-color-dark);
  --navHoverColor: var(--active-blue-color);
  --dimColor: var(--dim-color-dark);
}

.sidebar,
.top-header,
.breadcrumb,
.tab-content {
  background-color: var(--mainColor) !important;
}

.top-header {
  justify-content: space-between;
}
.top-right-header {
  display: flex;
}

.breadcrumb {
  border: 0;
}

.card-header {
  background-color: var(--fourthColor) !important;
}

.sidebar .nav-link,
.table,
.breadcrumb-item + .breadcrumb-item::before {
  color: var(--textColor);
}

.bg-secondary {
  color: #fff !important;
}

.sidebar {
  border-right: 1px solid var(--borderColor) !important;
  .nav-dropdown.open .nav-link {
    color: var(--textColor) !important;
  }
  .nav-link.active {
    color: var(--activeColor) !important;
    background-color: var(--fourthColor) !important;
  }
  .nav-link.active:hover {
    color: #fff !important;
  }
  .nav-item:hover a {
    color: #fff;
  }
  .nav-link:hover {
    background-color: var(--navHoverColor) !important;
  }
  .nav-title {
    background: var(--logoColor);
    min-height: 46px;
  }
}

.tab-content {
  border: 1px solid var(--borderColor) !important;
}

.card-header {
  border-bottom: 1px solid var(--borderColor) !important;
}

.form-control {
  background-color: var(--inputColor) !important;
  color: var(--textColor) !important;
  border: 1px solid var(--borderColor) !important;
}

.card-header .form-control[readonly],
.card-header .css-1insrsq-control,
.reservation-booking-div .form-control[readonly],
.reservation-booking-div .css-1insrsq-control,
.prices-div .form-control[readonly],
.prices-div .css-1insrsq-control {
  border: 1px solid var(--borderColor) !important;
  background-color: var(--fourthColor) !important;
  opacity: 1 !important;
  pointer-events: initial !important;
  cursor: not-allowed !important;
  color: var(--textColor) !important;
}
.card-header .css-1insrsq-control .css-lr9fot-singleValue,
.reservation-booking-div .css-1insrsq-control .css-lr9fot-singleValue,
.prices-div .css-1insrsq-control .css-lr9fot-singleValue {
  color: var(--textColor);
}
.form-control:disabled {
  opacity: 1 !important;
}
.row .form-control:disabled,
.row .form-control[readonly] {
  pointer-events: initial !important;
  cursor: not-allowed;
}

.css-6j8wv5-Input input {
  color: var(--textColor) !important;
}

.pull-right {
  float: right;
}

.container-fluid {
  padding: 0px !important;
}

.card {
  border-top: 1px solid var(--borderColor) !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-radius: 0px !important;
  background-color: var(--mainColor) !important;
}

.text-danger {
  cursor: pointer;
  font-weight: 600;
}

.no-padding {
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.required {
  color: var(--redColor);
}

.create-btn,
.submit-btn {
  float: right;
}

.paymentModalBox {
  min-width: 700px;
}

.btn-unlink {
  color: #fff;
}

.error-txt {
  color: var(--redColor);
  font-size: 13px;
  margin-left: 9px;
}

.filter-error-txt {
  color: var(--redColor);
  font-size: 12px;
}

.highlight-item,
.highlight-item span {
  color: #fdf4c7 !important;
}

.commonTable .btn-link {
  padding-left: 0px;
}

.link {
  color: var(--activeColor);
  cursor: pointer;
}

.paymentModalBox.productModal {
  min-width: 700px;
  max-height: 600px;
}
.item-modal {
  max-width: 420px;
}

.product-modal-show {
  margin-bottom: 20px;
  margin-top: -4px;
  float: right;
  margin-right: 20px;
  cursor: pointer;
  color: var(--activeColor);
}
.product-modal-show:hover {
  text-decoration: underline;
}
.form-control:disabled,
.form-control[readonly],
.btn-primary.disabled,
.btn-primary:disabled {
  pointer-events: none;
}
.date-filter {
  width: 130px;
}
.form {
  display: block;
  width: 50%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #e4e7ea;
  background-color: var(--fourthColor);
  background-clip: padding-box;
  border: 1px solid var(--secondColor);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.transfer-date {
  display: block;
  width: 300px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #e4e7ea;
  background-color: var(--fourthColor);
  background-clip: padding-box;
  border: 1px solid var(--secondColor);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-required {
  border-color: var(--redColor);
  .Select-control {
    border-color: var(--redColor) !important;
  }
}

.dim-div {
  pointer-events: none;
  opacity: 0.6;
}

.close {
  color: #fff;
}
.log-record {
  .log-line {
    line-height: 25px;
  }
  .green-color {
    color: #1de01d;
  }
  .red-color {
    color: var(--redColor);
  }
}

.rc-time-picker-panel-inner {
  height: 340px !important;
  .rc-time-picker-panel-select {
    max-height: none !important;
  }
}
.timepicker-disabled.form-control {
  background: #3a4149 !important;
  .rc-time-picker-input {
    background: #3a4149 !important;
  }
}
.rc-time-picker-input {
  padding: 0px !important;
  height: auto !important;
  line-height: 0px !important;
  background: var(--inputColor) !important;
  color: var(--textColor) !important;
  border: 0px !important;
  outline: none !important;
}
.rc-time-picker-panel-combobox li {
  color: #000 !important;
}
.checked.hotel-mobile-switch {
  .react-switch-bg {
    background-color: var(--activeColor) !important;
  }
}
.clientPromo .checked.hotel-mobile-switch {
  .react-switch-bg {
    background-color: var(--active-blue-color) !important;
  }
}
.unchecked.hotel-mobile-switch {
  .react-switch-bg {
    background-color: #ccc !important;
  }
}
.editorClassName {
  background-color: #fff !important;
  color: #000 !important;
}
.rdw-editor-toolbar {
  margin-bottom: 0px !important;
}
.rdw-editor-main {
  min-height: 250px;
}
.promo-title .rdw-editor-main {
  min-height: 150px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.refund-modal .row {
  padding-bottom: 10px;
}
.export-btn {
  position: absolute;
  top: 75px;
  right: 30px;
  z-index: 100;
}
.btn-filter-apply {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}
.NFilterRow.disableRow {
  pointer-events: none;
}
.card-header.transactionSync {
  height: 80px;
  .dateRangePicker {
    top: 95px !important;
  }
}

.create-passenger-icon {
  span {
    background-image: url("../assets/icons/normal-add-passenger.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
  span:hover {
    background-image: url("../assets/icons/hover-add-passenger.svg");
  }
}

.react-switch.active .react-switch-bg {
  background: var(--active-blue-color) !important;
}
.react-switch-bg {
  background: rgb(136, 136, 136);
}

.react-datepicker-popper {
  z-index: 2 !important;
}
.exportDatePicker {
  position: absolute;
  top: 10px;
  left: 233px;
  .DateRangePicker_picker {
    z-index: 2;
  }
}
.export-reservation-btn {
  position: absolute;
  top: 1px;
  left: 300px;
}
.hide {
  display: none;
}
.badge.bg-warning {
  color: var(--secondColor) !important;
}

.validDateContainer {
  padding-left: 20px;
  padding-top: 20px;
}

.visually-hidden {
  display: none;
}

.homepage-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  font-size: 30px;
  .spinner-border {
    width: 50px;
    height: 50px;
  }
}

.spinnerTable {
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
  .spinner-border {
    width: 50px;
    height: 50px;
  }
}

.fdiv{
  .ftitle {
    float: left;
  }
  .fsubtitle {
    display: inline;
    float: right;
    margin-top: 5px;
  }
  .fbtn{
    display: inline;
    margin-left: 15px;
    background-color: #75fa75;
    padding: 3px;
    border-radius: 2px;
    p {
      display: inline;
      color: #000;
    }
  }
}

.bdiv{
  display: flex;
  align-items: center;
  .btitle {
    float: left;
    display: inline;
    margin-bottom: 0px;
  }
  .bsubtitle {
    display: inline;
    margin-bottom: 0px;
  }
  .bbtn{
    display: inline;
    background-color: #75fa75;
    padding: 1px;
    border-radius: 2px;
    margin-left: 5px;
    p {
      display: inline;
      color: #3a4149;
    }
  }
}

/* Start of multiselect */
.multiSelectContainer {
  .searchWrapper {
    border: 1px solid var(--secondColor) !important;
    background: var(--fourthColor) !important;
    border-radius: 4px !important;
  }
  .optionListContainer {
    border: 1px solid var(--secondColor) !important;
    background: var(--fourthColor) !important;
    border-radius: 4px !important;
    color: #e4e7ea !important;
  }
  input {
    color: #fff !important;
  }
  ul {
    border: 1px solid var(--secondColor) !important;
  }
  li {
    color: #e4e7ea !important;
  }
  li:hover {
    background: var(--activeColor) !important;
    color: var(--textColor) !important;
  }
  .highlightOption {
    background: var(--fourthColor) !important;
    color: #e4e7ea !important;
  }
}
/* End of multiselect */

#exampleCustomCheckbox {
  position: initial;
  margin-top: 0px;
  margin-left: 0px;
}

.sameAsContactInfo {
  height: 50px;

  .css-b62m3t-container {
    font-size: 14px;
    width: 100%;
    background-color: #515b65;
    color: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.5);
  }

  .addressBook {
    padding: 7px;
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    border-radius: 2px;

    .selectItems {
      padding: 10px;
      background-color: red !important;
    }
  }
}

#transaction_date.form-control.btrans-form,input.form-control.btrans-form{
    background-color: #3a4149 !important;
    color: #ffffff !important;
    border: none !important;
}

@media (min-width: 1025px) {
  .sameAsContactInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 600px) {
  .sameAsContactInfo {
    .css-b62m3t-container {
      font-size: 14px;
      width: 100%;
    }
  }
}
